const TableSkeleton = () => {
  // Define the number of placeholder rows
  const placeholderRows = 10;

  // Define the number of columns (adjust as needed or make dynamic)
  const columnCount = 5;

  // Create an array for headers and rows
  const headers = Array.from({ length: columnCount }, (_, index) => `Header ${index + 1}`);
  const rows = Array.from({ length: placeholderRows }, () => Array(columnCount).fill(null));

  return (
    <div className="w-full">
      <div className="flex flex-wrap ac gap-8 justify-between mb-4">
        {/* Global Search Skeleton */}
        <div className="w-1/3 h-8 bg-gray-200 rounded animate-pulse"></div>
      </div>
      <div className="my-4 rounded-xl border border-gray-300 w-full">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="capitalize table-header-cell min-w-1 border-b border-gray-300 p-4">
                  <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-100">
                {row.map((_, cellIndex) => (
                  <td key={cellIndex} className="p-4">
                    <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination Skeleton */}
      <div className="flex justify-end items-center mt-4">
        <div className="w-32 h-8 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  );
};

export default TableSkeleton;
