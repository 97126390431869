import { Column } from "@tanstack/react-table";
import { useMemo } from "react";
import InputDebaounced from "../InputDebounced";

export const Search = ({ globalFilter, setGlobalFilter }) => {
  return (
    <InputDebaounced
      value={globalFilter ?? ""}
      onChange={(value) => setGlobalFilter(String(value))}
      className="px-2 py-1 rounded-lg b-1 b-sand7 shadow w-full fw-400 min-w-40 max-w-60 focus:b-prm9 outline-none flex-1"
      placeholder={"Search all columns..."}
    />
  );
};

type ColumnType = "string" | "js-date" | "boolean" | "number" | "null";

export const TableColumnFilter = ({ columnType, column }: { columnType: ColumnType; column: Column<any, unknown> }) => {
  if (columnType === "string") return <TextFilter column={column} />;
  if (columnType === "number") return <NumberFilter column={column} />;
  if (columnType === "boolean") return <BooleanFilter column={column} />;
  if (columnType === "js-date") return <TextFilter column={column} />;
  // if (columnType === "string-date") return <DateFilter column={column} columnFilterValue={columnFilterValue} />;
  if (columnType === "null") return <span className="text-xs c-gray10">NO DATA IN THE COLUMN</span>;
  return <span className="text-xs">NO FILTER FOR THIS DATA TYPE</span>;
};

const BooleanFilter = ({ column }: { column: Column<any, unknown> }) => {
  return (
    <>
      <select
        value={(column.getFilterValue() ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search...`}
        className="text-sm px-2 py-1 rounded-lg b-1 b-sand7 w-full fw-400 min-w-40 max-w-60 focus:b-prm9 outline-none flex-1"
      >
        <option value="TRUE">TRUE</option>
        <option value="FALSE">FALSE</option>
        <option value="NULL">NULL</option>
      </select>
    </>
  );
};

const TextFilter = ({ column }) => {
  const sortedUniqueValues = useMemo(() => Array.from(column.getFacetedUniqueValues().keys()).sort(), [column]);

  return (
    <div className="flex">
      <datalist id={`${column.id}-list`}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <InputDebaounced
        type="text"
        value={column.getFilterValue() ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search...`}
        className="text-sm px-2 py-1 rounded-lg b-1 b-sand7 w-full fw-400 min-w-40 max-w-60 focus:b-prm9 outline-none flex-1"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </div>
  );
};

const NumberFilter = ({ column }) => {
  return (
    <div>
      <div className="flex gap-1 ">
        <InputDebaounced
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(column.getFilterValue() as [number, number])?.[0] ?? ""}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min`}
          className="text-sm px-2 py-1 rounded-lg b-1 b-sand7 w-full fw-400 min-w-15 max-w-60 focus:b-prm9 outline-none flex-1"
        />
        <InputDebaounced
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(column.getFilterValue() as [number, number])?.[1] ?? ""}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max`}
          className="text-sm px-2 py-1 rounded-lg b-1 b-sand7 w-full fw-400 min-w-15 max-w-60 focus:b-prm9 outline-none flex-1"
        />
      </div>
      <div className="h-1" />
    </div>
  );
};
