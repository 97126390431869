import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useQuery } from "@tanstack/react-query";

export default function useGetUserMe() {
  type UserMe = User & { admin?: Admin; payer?: Payer; teacher?: Teacher } & {
    isAdmin?: boolean;
    isTeacher?: boolean;
    isPayer?: boolean;
  };
  const supabase = useSupabaseClient();
  return useQuery<UserMe, Error>({
    queryKey: ["userMe"],
    onError: (err: Error) => console.log("Error happened fetching userMe:", err),
    queryFn: async () => {
      const user = await getSupabaseAuthUser(supabase);
      if (!user) return null;
      const { data, error } = await supabase
        .from("user")
        .select(
          `*,
    payer (*) ,
    teacher (*),
    admin (*)
    `
        )
        .eq("id", user?.id)
        .single();

      if (error) throw error;

      let res = { ...data, email: user.email };
      // virtual properties
      if (res?.admin?.length || res?.admin?.id) res["isAdmin"] = true;
      if (res?.teacher?.length || res?.teacher?.id) res["isTeacher"] = true;
      if (res?.payer?.length || res?.payer?.id) res["isPayer"] = true;

      return res;
    },
  });
}

export const getSupabaseAuthUser = async (supabase) => {
  const sessionRes = await supabase.auth.getSession();
  if (!sessionRes) return null;
  if (sessionRes.error) throw new Error(sessionRes.error.message);
  if (!sessionRes?.data?.session) return null;
  const userAuthMe = sessionRes?.data?.session?.user;
  return userAuthMe;
};
