import Icon from "@/components/ui/Icon";
import Button from "../ui/button";

const Pagination = ({ table }) => {
  return (
    <div className="flex flex-wrap justify-end ac gap-8">
      <span className="text-sm font-semibold">{table.getPrePaginationRowModel().rows.length} Rows</span>
      <div className="flex gap-2 ac ">
        <span className="text-sm font-semibold">Rows per page</span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="field p-0 text-sm rounded-md bg-white border border-1 border-sand8"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option className="py-2" key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
      <p className="flex ac gap-2 text-sm font-semibold">
        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </p>
      <div className="flex ac gap-2 ">
        <Button
          iconButton
          variation="ghost"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="i-ph-caret-double-left" className="inline-block w-1em h-1em" />
        </Button>
        <Button
          iconButton
          variation="ghost"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="i-ph-caret-left" className="inline-block w-1em h-1em " />
        </Button>
        <Button iconButton variation="ghost" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          <Icon name="i-ph-caret-right" className="inline-block w-1em h-1em" />
        </Button>
        <Button
          iconButton
          variation="ghost"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <Icon name="i-ph-caret-double-right" className="inline-block " />
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
