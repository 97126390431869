import { toReadableDate } from "@/utils/formatter";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

import type { ColumnFiltersState, FilterFn, ColumnDef, Table } from "@tanstack/react-table";

import { useReducer, useState } from "react";

import { rankItem } from "@tanstack/match-sorter-utils";
import Icon from "@/components/ui/Icon";

import Pagination from "@/components/Table/Pagination";
import { Search, TableColumnFilter } from "@/components/Table/Filter";

type TData = unknown;
type TValue = unknown;

type TableProps = {
  schema?: any[] | null;
  data: any[];
};

const Table = ({ data, schema = null }: TableProps) => {
  const columns: ColumnDef<TData, TValue>[] = createSchema({ schema, data });
  const rerender = useReducer(() => ({}), {})[1];

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  // let theData = hasTotalRow ? [totalRowCalculator(data), ...data] : data;
  // theData = hasAverageRow ? [averageRowCalculator(theData), ...theData] : theData;

  const table = useReactTable({
    data,
    columns,
    // filterFns: {
    //   fuzzy: fuzzyFilter,
    // },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  // useEffect(() => {
  //   if (table.getState().columnFilters[0]?.id === 'fullName') {
  //     if (table.getState().sorting[0]?.id !== 'fullName') {
  //       table.setSorting([{ id: 'fullName', desc: false }])
  //     }
  //   }
  // }, [table.getState().columnFilters[0]?.id])

  return (
    <div className="py-4 lt-sm:max-table-width-in-mobile sm:max-w-main">
      <div className="flex flex-wrap ac gap-8 justify-between">
        <Search {...{ globalFilter, setGlobalFilter }} />
      </div>
      <div className="my-4 rounded-xl border-1 overflow-x-auto">
        <div className="rounded-xl">
          <table className="text-s border-collapse rounded-xl w-full table-auto">
            <TableHead table={table} />
            <TableBody table={table} />
          </table>
        </div>
      </div>
      <Pagination table={table} />
    </div>
  );
};

const TableHead = ({ table }: { table: Table<any> }) => {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                className="capitalize table-header-cell min-w-1 border-b-1 whitespace-nowrap"
              >
                {header.isPlaceholder ? null : (
                  <div className="space-y-2 text-start c-sand9 py-1">
                    <div
                      className={`${header.column.getCanSort() ? "cursor-pointer select-none" : ""}`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex gap-2 items-center">
                        <span title={header.column.columnDef.accessorKey} className="text-sm font-bold">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </span>
                        {header.column.getIsSorted() === "asc" && (
                          <Icon name="i-ph-arrow-up" className="c-orange11 inline-block w-1em" />
                        )}
                        {header.column.getIsSorted() === "desc" && (
                          <Icon name="i-ph-arrow-down" className="c-orange11 inline-block w-1em" />
                        )}
                        {header.column.getIsSorted() === false && (
                          <Icon name="i-ph-arrows-down-up" className="c-gray10 inline-block w-1em" />
                        )}
                      </div>
                    </div>
                    {header.column.getCanFilter() && (
                      <div className="text-s fw-400">
                        <TableColumnFilter
                          // value={header.column.getFilterValue()}
                          // onChange={(val) => {
                          //   header.column.setFilterValue(mapValue(val));
                          // }}
                          columnType={header.column.columnDef?.meta?.columnType as ColumnType}
                          column={header.column}
                        />
                      </div>
                    )}
                  </div>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

const TableBody = ({ table }) => {
  return (
    <tbody className="text-s">
      {table.getRowModel().rows.map((row) => {
        return (
          <tr key={row.id} className="border-b-1 last:border-0 hover:bg-gray1">
            {row.getVisibleCells().map((cell) => {
              const formatterFn = cell.column.columnDef.meta?.formatterFn;
              return (
                <td
                  key={cell.id}
                  title={formatterFn(cell.getValue())}
                  className="truncate text-sm font-medium table-body-cell select-all max-w-48"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default Table;

type Props = {
  children: any;
  schema?: any[];
  fallbackMessage?: string;
};

export const TableR = ({ children, schema, fallbackMessage = "There is no data!" }: Props) => {
  if (!children || children.length === 0) return <p>{fallbackMessage}</p>;
  return <Table data={children} schema={schema} />;
};

const createSchema = ({ data, schema = null }): ColumnDef<TData, TValue>[] => {
  // if (!data || data.length === 0) return schema ?? [];

  const keys = Object.keys(data[0]);

  const columns = [];

  for (const key of keys) {
    let firstRowNotNull = data.find((d) => d[key] !== undefined && d[key] !== null);
    let firstNotNull = firstRowNotNull?.[key];

    let columnType: ColumnType = "string";
    if (firstNotNull instanceof Date) columnType = "js-date";
    if (typeof firstNotNull === "boolean") columnType = "boolean";
    if (typeof firstNotNull === "number") columnType = "number";
    if (firstNotNull === null) columnType = "null";

    columns.push(
      completeColSchema(
        schema?.find((col) => {
          /*           console.log("🚀 ~ col:", col);
          console.log("🚀 ~ col.id:", col?.id);
          console.log("🚀 ~ key:", key); */
          return col?.id === key;
        }) ?? { id: key },
        columnType
      )
    );
  }

  return columns;
};

type ColSchema = {
  id: string;
  [key: string]: any;
};

type ColumnType = "string" | "js-date" | "boolean" | "number" | "null";

const completeColSchema = (colSchema: ColSchema, columnType: ColumnType): ColumnDef<TData, TValue> => {
  const header = (props) => props.column.id;
  const footer = (props) => props.column.id;

  const providedFormatter = colSchema?.meta?.formatterFn;
  let defaultFormatterFn = (value: any): string => String(value);

  if (columnType === "number") defaultFormatterFn = (value) => String(value);
  if (columnType === "js-date") defaultFormatterFn = (value) => toReadableDate(value);
  if (columnType === "boolean") defaultFormatterFn = (value) => String(value);
  if (columnType === "null") defaultFormatterFn = () => "NULL";

  const formatterFn = providedFormatter ?? defaultFormatterFn;

  let cell = (info) => {
    if (info.getValue() === null) return <span className="text-s c-gray8 fw-300">NULL</span>;
    if (info.getValue() === undefined) return <span className="text-s c-gray8 fw-300">UNDEFIEND</span>;
    /*     if (info.getValue() === "") return <span className="text-s c-gray8 fw-300">EMPTY STRING</span>; */
    if (colSchema.id.includes("In CAD"))
      return (
        <div className="flex gap-1 justify-between">
          {formatterFn(info.getValue())}
          <span className=" text-s c-gray8 fw-300">{` `}CAD</span>
        </div>
      );
    if (colSchema.id.includes("In IRR"))
      return (
        <div className="flex gap-1 justify-between">
          {formatterFn(info.getValue())}
          <span className=" text-s c-gray8 fw-300">{` `}IRR</span>
        </div>
      );
    if (colSchema.id.toLowerCase().includes("percentage"))
      return (
        <div className="flex gap-1 justify-between">
          {formatterFn(info.getValue())}
          <span className=" text-s c-gray8 fw-300">{` `}%</span>
        </div>
      );
    if (colSchema.id.toLowerCase().includes("minute"))
      return (
        <div className="flex gap- justify-between1">
          {formatterFn(info.getValue())}
          <span className=" text-s c-gray8 fw-300">{` `}Min.</span>
        </div>
      );

    return <>{formatterFn(info.getValue())}</>;
  };

  const meta = { columnType, formatterFn };

  const completeColSchema = {
    // id: colSchema.id,
    accessorKey: colSchema.id,
    accessorFn: colSchema.accessorFn,
    cell: colSchema.cell ?? cell,
    header: colSchema.header ?? header,
    footer: colSchema.footer ?? footer,
    meta: { ...meta, ...colSchema.meta },
  };

  return completeColSchema;
};
